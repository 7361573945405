@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Maven+Pro:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calligraffitti:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Yanone+Kaffeesatz:wght@200..700&display=swap');
:root {

  --color-1: #F5F5F5;
  --color-2: #333333; 
  --color-3: #B0B0B0;
  --color-4: #1A747D;
  --color-5: #bc9a5a;
 

  --font-main: "Open Sans", sans-serif;
  --font-header: "Maven Pro", sans-serif;

  --font-style-2: "Merriweather", serif;

}


body {
  /* background-color: var(--primary-background-color); */
  background-color: #F5F5F5; 
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: 1rem;

  color: var(--primary-color);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2.7rem;
}

h1, h2, h3 {
  font-family: var(--font-style-2);
}



strong, h4 {
  font-family: "roboto", "ubuntu";
  font-weight: 600;
  font-size: 16px;
  color: #0d0d0d;
}
h4 {
  margin-bottom: .5rem;
  margin-top: 1rem;
  font-size: 16px;
  line-height: 1.5;
}

p {
  /* margin-bottom: 1.25em;
  margin-top: 1.25em; */
  font-family: "roboto", "ubuntu";
  font-size: 16px;
  line-height: 1.75;
  color: #0d0d0d;
}


.gold {
  color: var(--color-5);
}

section {
  padding: 20px 0px;
}


.navbar {
  background-color: var(--color-2);
  color: var(--color-3);
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.navbar-brand,
.nav-link, .nav-item, .nav-link a {
  font-family: "roboto", "ubuntu";
  font-size: 16px;
  color: var(--color-1);
}

.nav-link:hover, .nav-link a:hover {
  color: var(--color-4);
}

.navbar-brand, .navbar-brand:hover {
  color: var(--color-4);
  font-weight: 500;
  font-family: var(--font-style-2);
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: 2rem;
}



.nav-underline .nav-link.active, .nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--color-4);
  border-bottom: none;
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  text-transform: uppercase;
}

.navbar-toggler {
  border-color: var(--color-5);
}

.navbar-toggler-icon {
  background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(188, 154, 90, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.top-bar {
  /* background-color: #16163f; */
  /* padding: 0px 12px; */
  font-size: 14px;
  /* color: white; */
  font-weight: 400;
  text-transform: uppercase;
}

.top-bar .fa {
  margin-left: 4px ;
  padding-right: 4px;
  padding-left: 4px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}




.page-title-box {
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);

 

  height: 160px;
}

h1.page-title {
  margin-top: 20px;
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;
}

.starter {
  margin-top: -46px!important;

  -webkit-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
}



.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.site-title {
  font-size: 2rem;
  color:  var(--primary-color)!important;
}

.site-title > span {
  font-size: 1.2rem;
  font-family: "Roboto";
  color: #0d0d0d;
  font-weight: 100;
}

.btn-primary {
  font-size: 1rem;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
  border-radius: 0;
  background-color: #1A747D;
  color: #fff;
  border: 1px solid transparent;
}

.testimonial {
  background-color: var(--color-2);
  text-align: center;
}

.testimonial  p {
  color:  var(--color-1);
  font-family: var(--font-style-2);
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: 0.1px;

}


footer {
  background-color: var(--color-4);
  color:  var(--color-1);
}

.footer_text {
  font-size: small;
  text-align: center;
  text-decoration: none;
  text-transform: lowercase;
}

.page_header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.page_header h1{
  background:rgba(252, 245, 235, .2);
  backdrop-filter: blur(4px);
  transition: background-color .3s cubic-bezier(.42, .00, .58, 1.00);
  
    /* height: 350px; */
  padding: 4rem;
  color: var(--color-2);
}


.dark_blur{
  background:rgba(0, 0, 0, .5);
  backdrop-filter: blur(4px);
  transition: background-color .3s cubic-bezier(.42, .00, .58, 1.00);
  padding: 4rem;
}


.blur{
  background:rgba(252, 245, 235, .2);
  backdrop-filter: blur(4px);
  transition: background-color .3s cubic-bezier(.42, .00, .58, 1.00);
  padding: 4rem;
}



.testimonial-slider {
  height: 100vh;
  margin: auto;
  padding: 52px 25px;
  text-align: center;
}

.testimonial-caption > p {
  font-size: 1.5rem;
  color: white;
  font-style: italic;

}

.testimonial-caption > p >  span {
  color: var(--color-5)!important;
  font-weight: 700;
  font-style: normal;
}

.header_image {
  width: 100%;
  object-fit: cover;
  height: 300px;
  object-position: 0% 50%;
}

.square_image {
  min-width: 100%;
  max-height: 300px;
  object-fit: cover;
  object-position: 100% 0;
  min-height: 300px;
}

.no_mob {
  display: block;
}

.mob_only {
  display: none;
}

.project_photo {
  max-height: 300px;
  width: 100%;
  padding: 5px;
  object-fit: cover;
  object-position: 100% 0;
  
 }

/*<500px S*/
@media only screen and (max-width: 499px) { 

  .dark_blur {
    padding: 0px;
    width: 100%;
  }

  .project_photo {
    max-height: 120;
   }



  .testimonial-slider {
    height: 100vh;
    margin: auto;
    padding: 20px;
    text-align: center;
  }

.testimonial-caption > p {
  font-size: initial;

}

  footer {
    text-align: center;
  }

  .page_header, .page_header h1 {
    height: 150px;
  }

  

  .header_image {
    height: 150px;
  }

  .no_mob {
    display: none;
  }
  
  .mob_only {
    display: block;
  }

}

/*500-1200 M*/
@media only screen and (min-width: 500px) and (max-width: 1199px) { }

/*>1200px L*/
@media only screen and (min-width: 1200px) {
  .page_header {
    height: 350px;
  }
 }

